
import Header from "./components/Header";
import Home from "./components/Home";
import NavBar from "./components/NavBar";

import Contact from "./components/Contact";
import Footer from "./components/Footer";
import BackToTopButton from "./components/BackToTopButton";

import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutTheBookPage from "./pages/AboutTheBookPage";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <>

     {/* <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-the-book" element={<AboutTheBookPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes> */}

      <div className="tablet:absolute w-full relative bg-secondary tablet:bg-transparent">
        <Header />
        <NavBar />
      </div>
      
      <Home />
      <HomePage />
      
      <div className="bg-secondary w-full h-12"></div>
        <Contact />
        <Footer />
        <BackToTopButton />
      
    
    </>
  );
}

export default App;
