import React from 'react'   
import Slider from "react-slick";
import bookCover from '../assets/images/book-cover.png'
import claimDenied from '../assets/images/claim-denied.png'
import tomAndLovey from '../assets/images/tom-and-lovey.png'

const BookCarousel = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      
      };
  return (
    <>
        <Slider {...settings}>
        <div className= 'bg-white p-10 !flex items-center justify-between flex-wrap tablet:flex-nowrap'>
                    <div className='w-full tablet:w-[50%] mb-10 tablet:mmb-0'>
                        <figure className='w-[350px] max-w-full mx-auto'>
                            <img src={bookCover} alt="book-cover" className='w-full'/>
                        </figure>
                        <h3 className='font-bold text-lg text-center'>Tom and Lovey Part: Under The Moon Into The Wood</h3>
                    </div>
                    <div className='w-full tablet:w-[50%]'>
                        <p className='text-justify mb-5'>On a ten year mission to avenge the brutal sacrifice of her man by Stargut, the local sheriff, himself on a mission to create the perfect man-beast, Lovey, abandoned by her spellbound friend Patty, is joined by a stranger. Tom is a preacher of sorts, who has followed the scent of evil for a hundred years. He mysteriously appears in the Village of Wrong, the rural Midwestern town and its mutant inhabitants mere creations of the devil lawman. Together the three converge under the moon into the wood down by the river at the doorsteps of hell.</p>
                        <a href="https://pageturner.us/bookstore/tom-and-lovey-under-the-moon-into-the-wood" target='_blank' rel="noreferrer" className='button-template'>Read More</a>
                    </div>
        </div>

        <div className= 'bg-white p-10 !flex items-center justify-between flex-wrap tablet:flex-nowrap'>
                    <div className='w-full tablet:w-[50%] mb-10 tablet:mmb-0'>
                        <figure className='w-[300px] max-w-full mx-auto'>
                            <img src={tomAndLovey} alt="book-cover" className='w-full'/>
                        </figure>
                        <h3 className='font-bold text-lg text-center'>Tom and Lovey: Pursuit of the Thunderbird</h3>
                    </div>
                    <div className='w-full tablet:w-[50%]'>
                        <p className='text-justify mb-5'>We learn about the Thunderbird, the blind ghost boy Junior, his ugly mutt Spooky, HollowEye, the spiritual coyote, LongTail, the mystical mountain creature, and the spirits of two Navajo blood-brothers, who all assist Tom and Lovey in their quest to solve the disappearance of Tom's mentor, Red Deer. Delayed in Missouri, two emerge in the Four Corners of the Southwest. The spirit of Tom's only love, Margit guides him through dreams as he recalls his long past search and confrontation in the infamous Lincoln County Jail. As events transpire, Lovey nurtures her newfound supernatural abilities—both as man and beast. A climactic encounter ensues with the evil spirit of Red Deer's granddaughter's dead-born twin, Devil no Name. Can Tim and Lovey break the curse of the witch's rebirthing rituals? Tom wagers his immortality. </p>
                        <a href="https://pageturner.us/bookstore/tom-and-lovey-part-ii-pursuit-of-the-thunderbird" target='_blank' rel="noreferrer" className='button-template'>Read More</a>
                    </div>
        </div>


        <div className= 'bg-white p-10 !flex items-center justify-between flex-wrap tablet:flex-nowrap'>
                    <div className='w-full tablet:w-[50%] mb-10 tablet:mmb-0'>
                        <figure className='w-[300px] max-w-full mx-auto'>
                            <img src={claimDenied} alt="book-cover" className='w-full'/>
                        </figure>
                        <h3 className='font-bold text-lg text-center'>Claim Denied</h3>
                    </div>
                    <div className='w-full tablet:w-[50%]'>
                        <p className='text-justify mb-5'>Meet Mr. Insurance Man, Benjamin "F" Johnson, a highly specialized adjuster and a workaholic. He is consumed equally by his dedication to ethical conduct and an addiction to a world of fantasy. <br/><br/> Follow Benny and his friends as they join the phantom on a joyride of horror to the timeless, medieval world of Lord Lawrence. Find out what's in the deep chambers of the asylum that await those invited. <br/><br/> Check your soul at the door. . . or lose it.</p>
                        <a href="https://pageturner.us/bookstore/claim-denied" target='_blank' rel="noreferrer" className='button-template'>Read More</a>
                    </div>
        </div>
        </Slider>
    </>
  )
}

export default BookCarousel