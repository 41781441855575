import React from 'react'
// import userFigure from '../assets/images/user-figure.png'
// import userRating from '../assets/images/user-rating.png'

const BookReview = () => {
  return (
    <>
    <section id='book-review' className='px-5 pt-8'>
        <div className='w-[1200px] mx-auto max-w-full'>
          <h2 className='heading-template text-left pl-20'>Book Reviews</h2>

            <div className='text-center bg-full bg-white p-10'>
                {/* <ul className='flex mb-20 justify-between flex-wrap tablet:flex-nowrap'>
                    <li className='mx-0 tablet:mx-5 mb-10'>
                        <figure className='w-[100px] max-w-full mx-auto'><img src={userFigure} alt="user figure" className='w-full mb-2'/></figure>
                        <figure className='w-[150px] max-w-full mx-auto'><img src={userRating} alt="user rating" className='w-full mb-4'/></figure>

                        <p>“Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.” </p>

                        <span className='mt-2 block'>- Lorem Ipsum</span>
                    </li>
                    <li className='mx-0 tablet:mx-5 mb-10'>
                        <figure className='w-[100px] max-w-full mx-auto'><img src={userFigure} alt="user figure" className='w-full mb-2'/></figure>
                        <figure className='w-[150px] max-w-full mx-auto'><img src={userRating} alt="user rating" className='w-full mb-4'/></figure>

                        <p>“Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.” </p>

                        <span className='mt-2 block'>- Lorem Ipsum</span>
                    </li>

                    <li className='mx-0 tablet:mx-5 mb-10'>
                    <figure className='w-[100px] max-w-full mx-auto'><img src={userFigure} alt="user figure" className='w-full mb-2'/></figure>
                        <figure className='w-[150px] max-w-full mx-auto'><img src={userRating} alt="user rating" className='w-full mb-4'/></figure>

                        <p>“Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.” </p>

                        <span className='mt-2 block'>- Lorem Ipsum</span>
                    </li>
                </ul>
                <a href="/" className='button-template'>Read More</a> */}

                <div className='mt-20'>
                      <i className='text-red-500'>No reviews Yet!</i>
                </div>
            </div>
        </div>
    </section>
</>
  )
}

export default BookReview