import React, { useRef }  from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


const ContactForm = () => {
  const MySwal = withReactContent(Swal)
  const form = useRef();

  const sendEmail = (e) => { 
    e.preventDefault();

    emailjs.sendForm('service_0qai3wa', 'template_oqn688x', form.current, 'gToTeXkVwW2WifPN4')
      .then((result) => {
        MySwal.fire({
          title: 'Success!',
          text: 'Successfuly sent message!',
          icon: 'successs',
        })
      }, (error) => {
        MySwal.fire({
          title: 'Error!',
          text: 'Message not sent!',
          icon: 'error',
        })
      });
  };
  
  return (
    <>
        <form ref={form} onSubmit={sendEmail}>
                    {/* <input type='hidden' value='rodneylungayan5@gmail.com' name='author_email'/> */}
                    <div className='flex items-center justify-between flex-wrap tablet:flex-nowrap'>
                        <div className='w-full tablet:w-1/2 mb-5 tablet:mb-0 tablet:mr-2'>
                            <label htmlFor="name" className='text-white text-1xl uppercase mb-2 block'>Name*</label> 
                            <input type="text" placeholder='ENTER TEXT HERE' className='w-full p-2 text-[#000]' name="from_name" required/>
                        </div>

                        <div className='w-full tablet:w-1/2 tablet:ml-2 ml-0'>
                            <label htmlFor="email" className='text-white text-1xl uppercase mb-2 block'>Email*</label> 
                            <input type="email" placeholder='ENTER TEXT HERE' className='w-full p-2 text-[#000]' name="from_email" required/>
                        </div>
                    </div>

                    <div className='mt-10'>
                        <label htmlFor="message" className='text-white text-1xl uppercase mb-2 block'>Message*</label>
                        <textarea name="message" className='w-full resize-none h-[200px] text-[#000] p-3'  required> </textarea>
                    </div>
                    <div className='g-recaptcha' data-sitekey='6LcRG3UjAAAAAAU9fs_w6TMmLRVqRbI6WtsCwZ28'></div>
                    {/* <button type="submit" onClick={handleSubmit} disabled={isLoading} className='button-template mt-10'> {isLoading ? 'Sending...' : 'Submit'} </button> */}
                    <button type="submit" className='button-template mt-10'>Submit </button>

                 </form>
    </>
  )
}

export default ContactForm