import React from 'react'
import ContactForm from './ContactForm'


const Contact = () => {
  return (
    <>
          <section id='contact' className="bg-contact h-screen bg-cover bg-no-repeat flex items-center px-5">
        
            <div className='flex items-center justify-between w-[1200px] mx-auto'>

                <div className='w-full'>
                 <h2 className='heading-template text-center mb-10'>Get in Touch with Author</h2>

                 <ContactForm/>

                </div>

            </div>
        </section>
    </>
  )
}

export default Contact