import AboutTheAuthor from "../components/AboutTheAuthor";
import AboutTheBook from "../components/AboutTheBook";
import BookReview from "../components/BookReview";


const HomePage = () => {
  return (
    <>
   
       <div className="bg-secondary w-full h-12"></div>
       <div className="d-block bg-bottom bg-cover">
         <AboutTheAuthor/>
         <AboutTheBook/>
         <BookReview/>
       </div>
  

    </>
  )
}

export default HomePage