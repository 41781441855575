import React from 'react'
import siteIcon from '../assets/images/site-icon.png'

const Footer = () => {
  return (
    <>
        <footer className='bg-secondary p-5 flex items-center justify-center flex-wrap tablet:flex-nowrap'>
          <figure className='w-[20px] mr-3 mb-5 mobile:mb-0'><img src={siteIcon} alt="site icon" className='w-full'/></figure>
          <p className='text-white text-center tablet:text-left'>Copyright ©{(new Date().getFullYear())} <a href="https://pageturner.us" target='_blank' rel="noreferrer" className='hover:underline'>PageTurner, Press and Media</a>. All rights reserved.</p>
        </footer>
    </>
  )
}

export default Footer