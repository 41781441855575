import React from 'react'

const NavBar = () => {


  
    return (
        <>
     
            <nav className='text-white bg-secondary mobile:block py-4 px-5'>
        
             
                    <ul className='navbar-menu flex items-center justify-center  font-tertiary text-lg  flex-wrap text-center tablet:flex-nowrap'>
                        <li className='tablet:mx-5 mx-auto my-3 tablet:my-0 w-full'>
                            <a href="#home" className=' hover:underline'>Home</a>
                        </li>
                        <li className='tablet:mx-5 mx-auto my-3 tablet:my-0 w-full'>
                            <a href="#about-the-author" className=' hover:underline'>About the Author</a>
                        </li>
                        <li className='tablet:mx-5 mx-auto my-3 tablet:my-0 w-full'>
                            <a href="#about-the-book" className=' hover:underline'>About the Book</a>
                        </li>
                        <li className='tablet:mx-5 mx-auto my-3 tablet:my-0 w-full'>
                            <a href="#book-review" className=' hover:underline'>Book Reviews</a>
                        </li>
                        <li className='tablet:mx-5 mx-auto my-3 tablet:my-0 w-full'>
                            <a href="#contact" className=' hover:underline'>Contact</a>
                        </li>
                    </ul>
              
            </nav>
        </>
    )
}

export default NavBar