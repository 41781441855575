import React from 'react'

const Header = () => {
  return (
    <>
        <section className='p-5'>
           <h1 className='heading-template text-center'>Gerald Gaillard</h1>
        </section>
    </>
  )
}

export default Header