import React from 'react'
import bookTitle from '../assets/images/book-title.png'
import bookCover from '../assets/images/book-cover.png'

const Home = () => {
  return (
    <>
        <section id='home' className="bg-home h-screen bg-cover bg-no-repeat flex items-center p-5">
        
          <div className='flex items-center desktop:justify-between justify-center w-[1200px] mx-auto flex-wrap mobile:flex-nowrap'>
           
            <figure className='mobile:w-[190px] laptop:w-[350px] desktop:w-[500px] max-w-full w-full '>
              <img src={bookTitle} className='w-full' alt='book title'/>
            </figure>
              
            <div>
                <figure className='mobile:w-[190px] laptop:w-[350px] desktop:w-[500px] max-w-full w-full '><img src={bookCover} className='w-full' alt='book cover'/></figure>
                <a href="https://pageturner.us/bookstore/tom-and-lovey-under-the-moon-into-the-wood" target='_blank' rel="noreferrer" className='button-template'>Order Now</a>
            </div>
          </div>

        </section>
    </>
  )
}

export default Home