import React from 'react'

import BookCarousel from './BookCarousel'



const AboutTheBook = () => {
  return (
    <>
        <section id="about-the-book" className='px-5 pb-8'>
            <div className='w-[1200px] mx-auto max-w-full'>
                 <h2 className='heading-template text-right pr-20'>About the Book</h2>
                 <BookCarousel/>
                 
            </div>
        </section>
    </>
  )
}

export default AboutTheBook