import React from 'react'
import authorImage from '../assets/images/author-image.jpg'
import textLineBefore from '../assets/images/text-before.png'


const AboutTheAuthor = () => {
  return (
    <>
        <section id='about-the-author' className='px-5 py-8'>
            <div className='w-[1200px] mx-auto max-w-full'>
              <h2 className='heading-template text-left mobile:pl-20 p-0'>About the Author</h2>

              <div className='bg-white p-8 flex items-center justify-between flex-wrap tablet:flex-nowrap'>
                    <div className='w-[100%] laptop:w-[60%] text-justify mb-5 order-2 tablet:order-1'>
                        <div>
                            <img src={textLineBefore} alt="line 1" className='m-0 w-[644px] max-w-full ml-auto mb-4'/>
                           <h3 className='text-right text-5xl font-primary text-primary'>G.R Jerry</h3>
                           <img src={textLineBefore} alt="line 2" className='w-[644px] max-w-full ml-auto mt-2 mb-10'/>
                        </div>
                        <p>Born and educated near north shore suburban Ohio, G.R. nurtured his interest in the macabre early in life while developing his writing skills in a variety of career positions. He completed a military obligation then set to exploring our great country and his creative aspirations during his time as a working weekend hippie, a freak as he suggests. After an insurance career ending in Chicago, G.R. began putting his dreams to words, the mystical, magical, fantastical images of the mind and the horror of it all. Currently corralled by a small village near the Sandia Mountains in the Land of Enchantment with his wife, Patricia, he is surrounded by stars and stories speaking to him from the dark heavens above . . . yet waiting to be told.</p>
                    </div>

                    <figure className='w-[300px] order-1 mx-auto laptop:w-[400px] tablet:ml-10 mb-5 tablet:order-2'>
                        <img src={authorImage} alt="author" className='w-full'/>
                    </figure>
              </div>
            </div>
        </section>
    </>
  )
}

export default AboutTheAuthor